import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from "firebase/app"
import 'firebase/analytics';

import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
const firebaseConfig = {
    apiKey: "AIzaSyDQvZqDwVNo9-ktyb_qAcdMGGGqNVOP4So",
    authDomain: "salut-b4342.firebaseapp.com",
    projectId: "salut-b4342",
    storageBucket: "salut-b4342.appspot.com",
    messagingSenderId: "879042532416",
    appId: "1:879042532416:web:3409d63bea95f28ed4bba5"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics()
createApp(App)
    .use(store)
    .use(router)
    .use(ElementPlus)
    .mount('#app')
