import {createStore} from 'vuex'
import firebase from 'firebase/app';
import 'firebase/firestore';

export default createStore({
    state: {
        prices: {},
        branch: [],
        others: {
            schoolLink: {
                link: '',
                text: ''
            }
        }
    },
    mutations: {
        updateBranch(state, data) {
            state.branch.push(data)
        },
        updatePrices(state, data) {
            state.prices = data
        },
        updateOthers(state, data) {
            state.others = data
        },
        writeBranch(state, data) {
            state.branch = data
        }
    },
    actions: {
        loadAppData(context) {
            firebase.firestore().collection("general").doc('prices').get().then(doc => {
                context.commit('updatePrices', doc.data())
            })
            firebase.firestore().collection("general").doc('others').get().then(doc => {
                context.commit('updateOthers', doc.data())
            })
           return firebase.firestore().collection("branch").get().then((querySnapshot) => {
               let result = []
                querySnapshot.forEach((doc) => {
                    result.push({...doc.data(), id:doc.id})
                });
               context.commit('writeBranch', result)
           });
        },
        saveBranch(context, data) {
            data.forEach((el) => {
                if (el.id.length > 1) {
                   return firebase.firestore().collection("branch").doc(el.id).set(el);
                } else {
                   return firebase.firestore().collection("branch").doc().set(el);
                }
            })
        },
        savePrices(context, data) {
            firebase.firestore().collection("general").doc('prices').set(data);
        },
        saveOthers(context, data) {
            firebase.firestore().collection("general").doc('others').set(data);
        },
        deleteBranch(context, branchId) {
            firebase.firestore().collection("branch").doc(branchId).delete()
        }
    }
})
