<template>
    <router-view v-slot="{ Component }">
        <component :is="Component" />
    </router-view>
</template>
<script>
import {mapActions} from 'vuex'

export default {
  methods: {
    ...mapActions(['loadAppData'])
  },
  created() {
  }
}
</script>
<style lang="scss">
a {
  cursor: pointer;
}
.hero__social-item {
  svg {
    fill: #fff;
    opacity: 0.8;
    transition: all .3s ease-in-out;

  }
  &:hover svg {
    opacity: 1;
  }
}
.hero__social-item + .hero__social-item {
  margin-left: 12px;
}
@media (max-width: 767px) {

  .home .el-tabs__nav-scroll {
    overflow-x: scroll;
    padding-bottom: 0px;
    &::-webkit-scrollbar {
      height: 0px;
    }
  }
}
</style>
