import {createRouter, createWebHashHistory} from 'vue-router'
const Home = () => import('@/views/home/Home.vue')
const Admin = () => import('@/views/admin/Admin.vue')
const NotFoundPages = () => import('@/views/NotFoundPages')


const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        name: 'admin',
        component: Admin,
        path: '/admin'
    },
    {
        name: 'notFound',
        component: NotFoundPages,
        path: '/:pathMatch(.*)*'
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})

export default router
